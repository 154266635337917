// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  min-height: 100%;
  font-size: 13px;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#root,
.app-container {
  height: inherit;
}

#root {
  overflow-x: auto;
}

.app-container {
  min-width: 1300px;
}

main {
  flex: 1 0;
}

.container {
  width: 100%;
  padding: 0 30px;
  // max-width: 1360px;
  margin: 0 auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}


/**
 * Basic styles for links
 */
a {
  color: $main-text;
  text-decoration: none;

  // &:hover,
  // &:active {
  //   color: $main-blue;
  // }

  &.link {
    color: $main-purple;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

/**
 * Images
 */
img {
  max-width: 100%;
  display: block;
}

/**
 * ANTd global overwrites
 */
.app-container {
  & > .ant-layout {
    min-height: 100%;
  }
}

.ant-table {
  overflow-x: auto;
}

.ant-btn-primary {
  background-color: $main-theme-color;
  border-color: $main-theme-color;

  &:active,
  &:hover,
  &:focus {
    background-color: lighten($main-theme-color, 10);
    border-color: lighten($main-theme-color, 10);
  }
}

.ant-btn-background-ghost {
  // background-color: transparent;
  border-color: $main-theme-color;
  background-color: $main-theme-color;
  color: $main-theme-color;

  &:active,
  &:hover,
  &:focus {
    background-color: $main-theme-color;
    border-color: $main-theme-color;
    color: $main-theme-color;
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color:  $main-theme-color;
  border-color: $main-theme-color;
  background: $main-theme-color;

  &:active,
  &:hover,
  &:focus {
    background-color:  $main-theme-color;
    border-color: $main-theme-color;
    background: $main-theme-color;
  }
}

.ant-radio-button-wrapper-checked {
  box-shadow: -1px 0 0 0 $main-theme-color;

  &:hover {
    box-shadow: -1px 0 0 0 $main-theme-color;
  }
}

.ant-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.has-feedback {
  .ant-input-password-icon {
    margin-right: 18px;
  }
}

.addon-row {
  .ant-input-number {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/**
 * Helper atom styles
 */

 // Display
.d-b {
  display: block;
}

.d-ib {
  display: inline-block;
}

.d-i {
  display: inline;
}

// Flex params
.fx-nw {
  flex-wrap: nowrap;
}

.fx-nos {
  flex-shrink: 0;
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

// Visibility
.hidden {
  visibility: hidden !important;
}

// Opacity
.op-25 {
  opacity: 0.25;
}

// Height
.h-100p {
  height: 100%;
}

.w-100p {
  width: 100%;
}

// Text align
.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

// White space styles
.ws-nw {
  white-space: nowrap;
}

// Font size
.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

// Font weight
.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

// Padding
.p-0 {
  padding: 0;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}


.pr-5 {
  padding-right: 5px;
}

.pxy-20 {
  padding: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

// Margin
.m-0 {
  margin: 0;
}

.ml-a {
  margin-left: auto;
}

.m-a {
  margin: auto;
}

.mr-a {
  margin-right: auto;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}


.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

// Cursour
.c-p {
  cursor: pointer;
}

// Position
.pos-r {
  position: relative;
}



.tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke {
  width: 100px !important;
}

.tinymce-content p {
  padding: 0;
  margin: 2px 0;
}

// Modal
.ant-modal .ant-modal-content {
  padding: 0;

  .ant-modal-header, .ant-modal-body {
    padding: 20px;
    margin-bottom: 0;
  }

  .ant-modal-close-icon:hover {
    color: white;
  }
}

.container {
  // height: 100vh;

  .noAccess {
    position: absolute;
    top: 40%;
    left: 43%;
    text-align: center;

    h1 {
      font-size: 110px;
    }
    .hint {
      font-size: 20px;
      text-align: center;
    }
    a {
      margin-top: 20px;
    }
  }
}

.text-primary {
  color: $main-theme-color
}

.link-primary {
  color: $main-theme-color;

  &:hover {
    color: $main-theme-color;
  }
}