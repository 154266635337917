// -----------------------------------------------------------------------------
// Every Page global styles
// -----------------------------------------------------------------------------
.login-page {
  height: 100vh;
  background: no-repeat center/100%;
  background: #f0f2f5;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);

  &.page {
    padding: 30px 10px;
  }

  .layout-content {
    padding-top: 40px;
    width: 100%;
    max-width: 303px;
  }

  .layout-top {
    text-align: center;

    img {
      margin-right: 16px;
      width: 70px;
    }

    span {
      font-size: 33px;
      font-weight: 600;
      color: rgba(0,0,0,.85);
    }

    p {
      margin-top: 12px;
      margin-bottom: 40px;
      font-size: 14px;
      color: rgba(0,0,0,.45);
    }
  }
}


.page {
  padding: 30px 0;

  .img-thumbnail {
    width: 150px;
    height: 98px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .page-subheader {
    margin-bottom: 30px;
  }

  .general-table {

    .ant-spin-container {
      // min-height: 500px;
    }

    tbody {
      background-color: #fff;
      // min-height: 500px;
    }
  }

  .languages-list {

    span {
    display: flex;
    flex-direction: column;

      &:last-of-type ~ .ant-divider {
        display: none;
      }

      img:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    .ant-divider {
      margin: 10px 0;
      width: 26px;
      min-width: unset;
    }

    img {
      height: 16px;
      width: 26px;
    }
  }

  .flag {
    display: inline-block;
    border: 1px solid #cdcdcd;
  }

  .galleries-list {
    .gallery-type {
      background-color: $light-grey;
    }
  }

  .gallery-type.private {
    color: red;
  }

  .steps-content {
    .gallery-item {
      z-index: 1 !important ;
    }

    .form-controls {
      text-align: end;
    }
  }

  .property-steps-container {
    width: 100%;
    max-width: 960px;
    margin: auto;
  }

  .sale {
    color: $blue-sale;
  }

  .rent {
    color: $green-rent;
  }

  .property-identifier {
    font-weight: 600;
    color: $light-blue;
  }

  .map-container {
    height: 350px;
  }

  .mb-if-not-single:not(:last-child) {
    margin-bottom: 20px;
  }

  .filter-form {
    .ant-form-item-label {
      padding-bottom: 3px;
    }
  }

  .profile-container {
    width: 100%;
    max-width: 800px;
  }

  .add-item-link {
    margin-top: -20px;
    display: block;
  }

  .button-upload {
    .ant-upload {
      // margin: 20px 0px;
      // padding: 0px 10px;
      width: 190px;
      height: 118px;
    }
  }

  .center-spinner-cont {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.single {

  .page-subheader {
    margin-bottom: 50px;
  }

  .subheader-top {
    padding: 20px 30px;
  }

  .status-menu-btn {
    .ant-select-selection--single {
      border: none;
      outline: none;
    }
  }

  .subheader-body {
    padding: 0 30px 20px;
  }

  .subheader-bottom {
    padding: 0 30px;
    border-top: 1px solid $border-separator;
  }

  .subheader-navigation {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;


    li {
      margin-right: 30px;
      display: flex;
      line-height: 60px;
      border-bottom: 1px solid transparent;

      a {
        text-decoration: none;
      }

      &:last-of-type {
        margin-right: initial;
      }

      &.active {
        border-bottom: 1px solid $main-theme-color;
      }
    }
  }

  .avatar {
    font-size: 40px;
  }

  .actions-menu.properties {
    border: 1px solid rgba(131, 131, 131, 0.3);

    .anticon {
      margin-right: 0;
      font-size: 20px;
    }
  }
}

.tab {
  width: 100%;

  .card-list {
    .contact-card-item {
      margin-bottom: 30px;
    }
  }

  .contact-detail-title {
    padding-bottom: 6px;
    display: block;
    font-weight: 300;
  }

  .deal-header {
    font-size: 14px;
  }

  .deal-tabs-container {
    .ant-tabs-bar {
      margin-bottom: 30px;
      border: none;
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        margin: 0;
        padding: 11px 30px;
        background: #fff;
        border: 1px solid #ebedf2;
        border-radius: 0;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }

      .ant-tabs-tab-active {
        color: #fff;
        background-color: $main-theme-color;
      }
    }
  }

  .draggable-component {
    overflow-x: auto;

    .card-container {
      min-height: 200px;
      width: 250px;
      padding: 10px;
      border-radius: 4px;
      background: rgba(196, 197, 214, 0.15);
      border-radius: 3px;

      .smooth-dnd-container {
        min-height: 120px;
        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        }
      }
    }

    .card {
      margin-bottom: 15px;
      padding: 10px;
      border-bottom: 2px solid #cdcdcd;
      background-color: #fff;
      box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
      transition: transform .3s ease;
      cursor: grab;
    }

    .card-property-name {
      font-weight: 500;
      font-size: 15px;
      color: $main-theme-color;
      cursor: pointer;
    }

    .cards-drop-preview {
      background-color: pink;
    }

    .drop-preview {
      border: 1px dashed;
      background-color: lighten($main-purple, 30);
    }

    .card-ghost {
      transform: rotate(-5deg);
    }
  }

  .property-steps-container {
    width: 100%;
    max-width: unset;
    margin: auto;
  }
}
.loader {
  width: 100%;
  position: relative;
}

.loader-spiner-container {
  // position: absolute;
  // top: 0;
  // left: 0;
  // background-color: rgba(0,0,0,.3);
  // width: 100%;
  // height: 100%;
  // z-index: 999;
  // pointer-events: none;

  .ant-spin-dot-spin {
    position: fixed;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    color: black;
    z-index: 1;
  }
}