.single-header {
  background: #fff;
  padding: 20px 25px 0 25px;
  border-radius: 2px;
  box-shadow: 0 1px 15px 1px rgba(69,65,78,.08);

  .property-title {
    margin: 0;
    font-size: 22px;
  }

  .general-info {
    display: flex;
    // width: 25%;

    .property-img {
      margin-right: 20px;
    }
  }

  .property-info {

    div {
      margin-bottom: 8px;
    }
  }

  .property-info-cont {
    border-bottom: 1px solid #ebedf2;
    padding-bottom: 20px;
  }

  .single-header-contenet {
    display: flex;
    justify-content: space-between;

    .actions-menu {
      border: none;
      background: none;
    }

    .header-status-menu {
      display: flex;
      margin-bottom: 30px;

      .status-menu-btn {
        margin-left: 10px;

        .ant-select-selection--single {
          border: none;
          background-color: #34bfa3;
          color: white;
        }
        .ant-menu-submenu-arrow {
          display: none;
        }

        .ant-menu-inline {
          position: absolute;
          width: 125px;
          margin: 0;
          z-index: 200;
          padding: 0;

          .ant-menu-item {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.gallery-item {
  padding: 5px;
  position: relative;
  height: 153px;
  margin-bottom: 20px;
  z-index: 1;
  margin: 0 10px 20px 0;

  i.flaticon-menu-2:before {
    font-size: 18px;
    font-weight: 900;
  }

  .gallery-type {
    position: absolute;
    width: 200px;
    border: 1px solid #eaebf0;
    padding: 3px 10px;
    margin: 0;
    font-weight: 400;
    background: #f2f3f8;
    bottom: 0;
    left: 5px;
    height: 30px;

    .private {
      color: red;
    }
  }
}

.status-menu-btn {
  .ant-select-selector {
    border: none !important;
  }
}


.ant-menu {
  .anticon {
    margin-right: 10px;
  }
}

.ant-select-selection-item {
  display: flex;
}

.gallery-type {
  display: flex;
  align-items: center;
}

.ant-upload-select-picture-card {
  display: table;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color .3s ease;
  transition: border-color .3s ease;
}

.details-tab {
  color: #575962;

  .details-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
    box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);

    .video-item {
      margin-right: 10px;
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      margin: 0;
      border-bottom: 1px solid #ebedf2;
    }

    .section-content {
      padding: 20px;

      .ant-row {
        margin-bottom: 18px;
      }

      .galleries-list {
        margin-bottom: 50px;
      }

      .gallery-video-title {
        margin-bottom: 20px;
      }

      .red-color {
        color: red;
      }

      .green-color {
        color: green;
      }

      .row-header {
        margin-bottom: 20px;
        color: #575962;
      }

      .details-col {
        display: flex;
        flex-direction: column;

        &.total-area {
          margin: 10px 0 30px 0;
        }

        .row-details-header {
          margin-bottom: 20px;
          color: #575962;
        }

        .rows-container {
          display: flex;
          margin-bottom: 10px;

          .details-row {
            width: 30%;
            font-weight: 300;

            .details-value {
              font-weight: 500;
            }

            div {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .location-details {
      display: flex;
      margin-bottom: 20px;

      .location-row {
        width: 28%;
      }
    }

    .map-container {
      height: 500px;
      width: 100%;
    }
  }
}

.ant-menu.ant-menu-horizontal >.ant-menu-submenu-open::after {
  border: none;
}
.ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}