// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

// Switch inner text paddings
.switch-innertext {
  margin: 0;
  padding: 0 6px;
}
