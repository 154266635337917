.report-table-header {
  background: $main-heading-bg;
  color: white;
}

.ant-pagination-item-active:hover,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:active,
.ant-pagination-item:hover,
.ant-pagination-item-active {
  border-color: $main-theme-color;

  a, a:hover, a:focus {
    color: $main-theme-color;
  }
}