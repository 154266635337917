// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main-header {
  height: unset;
  width: 100%;
  padding: 0 15px;
  position: sticky;
  top: 0;
  background-color: $main-heading-bg;
  z-index: 10;

  .nav-block,
  .user-block {
    flex-wrap: nowrap;
  }

  .logo-container {
    flex: 0 1 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 65px;
    }
  }

  .navigation-container {
    flex: 1 1 auto;
  }

  .nav-item {
    height: 100%;
    padding: 0 20px;
    display: flex;
    color: #868aa8;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: white;
    }

    &.active {
      font-weight: 500;
      color: #fff;
      background-color: $main-heading-highlited;
    }

    .icon {
      margin-right: 8px;
      font-size: 18px;
    }
  }

  .nav-user {
    color: #fff;
    cursor: pointer;
  }

}
