// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
///$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Color variables
$main-theme-color: rgba(200,146,17,.85);
$main-purple: #00303c;
$main-text: #212529;

$main-heading-bg: #00303c;
$main-heading-highlited: #1B4051;
$border-separator: #ebedf2;

$light-blue: #36a3f7;
$blue-sale:#40b6fe;
$green-rent: #34bfa3;
$task-done: #c4c5d6;

$light-grey: #f2f3f8;


/// Breakpoints variables
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
