// Portlet styles

.portlet {
  border-radius: 2px;
  box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  background-color: #fff;

  .portlet-head {
    padding: 15px 30px;
  }

  .portlet-body {
    padding: 30px;
  }

  .portlet-head {
    border-bottom: 1px solid $border-separator;
  }
}
