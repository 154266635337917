/**
 * Basic typography style for copy text
 */
body {
  // background-color: $main-gray;
  font: normal 100% / 1.4 $text-font-stack;
  font-family: 'Montserrat', sans-serif;
  color: $main-text;
}

h1,h2,h3,h4,h5,h6, p, span {
  padding: 0;
  margin: 0;
  color: inherit;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

.accent {
  font-weight: 500;
}

strong {
  font-weight: 500;
}