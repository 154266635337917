.property-status {
  .circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;

    &.published {
      background-color: #34bfa3;
    }

    &.draft {
      background-color: #c4c5d6;
    }

    &.archive {
      background-color: #f4516c;
    }

    &.review {
      background-color: #cea143;
    }

  }
}

.galleries-list .ant-upload-list-item-actions, .gallery-item.button-upload .ant-upload-list-item-actions{
  display: none;

}

.galleries-list .ant-upload-list-item {
  position: relative;
}

.gallery-item {
  .ant-upload-list-item:hover {
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 118px;
      background-color: rgba(0, 0, 0, 0.45);
      transition: all 0.3s;
      content: " ";
    }
    .ant-upload-list-item-actions {
      display: block;
      position: absolute;
      color: white;
      opacity: 0.8;
      top: 45%;
      left: 42%;
      z-index: 10;
      &:hover {
        opacity: 1;
      }
    }
  }
}