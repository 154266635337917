// Contact card styles

.contact-card-item {
  border-radius: 2px;
  box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  background-color: #fff;

  .contact-card-header {
    padding: 20px 30px;
    border-bottom: 1px solid $border-separator;
  }

  .contact-card-content {
    padding: 30px;
    word-break: break-word;
  }

  .contact-card-footer {
    border-top: 1px solid $border-separator;
    padding: 15px 30px;
  }

  .three-dots {
    font-size: 22px;
    cursor: pointer;
  }

  strong {
    font-weight: 500;
  }

  &.with-tabs {
    background-color: transparent;
    box-shadow: none;

    .contact-card-content {
      padding: 0;
    }

    .ant-tabs-card-bar {
      margin: 0;
    }

    .ant-tabs-card-content {
      padding-top: 25px;
      padding-bottom: 30px;
      background-color: #fff;
      box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
    }

    .gallery-content {
      padding: 0 25px;
    }
  }
}