@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.languages-list span {
  display: flex;
  flex-direction: column;
}

.languages-list:last-of-type ~ .ant-divider {
  display: none;
}

.languages-list img:not(:last-child) {
  margin-bottom: 5px;
}


.languages-list .ant-divider {
  margin: 10px 0;
  width: 26px;
  min-width: unset;
}

.languages-list  img {
  height: 16px;
  width: 26px;
}

.tox .tox-toolbar__group {
  padding: 0 !important;
}