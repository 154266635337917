// Timeline component styles

.timeline {

  .ant-timeline-item {
    font-size: 13px;
  }

  .ant-timeline-item-content {
    margin-left: 35px;
   
  }
}
