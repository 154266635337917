// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  cursor: pointer;
}

.download-zip {
  color: $main-purple;
  cursor: pointer;
  padding-left: 10px;
  margin-left: 10px;
  font-size: 12px;
  border-left: 1px solid #333;
}

.ant-btn:hover, .ant-btn:focus {
  color: $main-theme-color;
  border-color: $main-theme-color;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: white;
  border-color: $main-theme-color;
}

.ant-btn:disabled:hover {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}
