// -----------------------------------------------------------------------------
// This file contains all styles related to the modal of the site/application.
// -----------------------------------------------------------------------------
.modal {

  .ant-modal-header {
    background-color: $main-heading-bg;
  }

  .ant-modal-close {
    color: #fff;
  }

  .ant-modal-title {
    color: #fff;
    font-size: 15px;

    a {
      color: #fff;
      border-bottom: dashed 1px #f2f3f9;
    }
  }

  .ant-modal-body {
    background-color: $light-grey;
  }

}

.button-upload {
  .ant-upload {
    padding: 0;
    width: 175px;
    height: 118px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.ant-modal-wrap {

  .ant-modal.preview-image {
    width: fit-content !important;
    max-width: 1200px;

    .ant-modal-content {
      margin: 50px;

      img {
        width: unset !important;
      }
    }
  }
}

